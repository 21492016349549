<template>
<span>
  <div class="col-12" v-if="!loaded">
    <div class="loading">
      <i class="fas fa-circle-notch fa-2x fa-spin"></i>
    </div>
  </div>
  <div class="col-12" v-else-if="apiErrors.errorBool">
    <div class="error-container">
      <p class="center-text">
        <i class=" fa fa-2x fa-frown"></i>
        <br>
        <br>
        Es ist leider ein Fehler aufgetreten. Bitte versuch die Seite neu zu laden.
      </p>
    </div>
  </div>
  <div class="form-wrap" v-else>
    <div class="form-inner-wrap" v-if="!sent">
      <div class='registration-navigation'>
        <div class="row">
          <div class="col-md-3 justify-content-between">
            <div class='step-view center' :class='{active: currentStep >= 1}' data-step='1' v-on:click='goToStep(1)' :disabled="currentStep === 1">
              <div class='icon-wrap'>
                <div class='icon'>1</div>
              </div>
            </div>
          </div>
          <div class="col-md-3 justify-content-between">
            <div class='step-view center' :class="{active: currentStep >= 2, inactive: currentStep <= 1}" data-step='2' v-on:click='goToStep(2)' :disabled="currentStep === 2">
              <div class='icon-wrap'>
                <div class='icon'>2</div>
              </div>
            </div>
          </div>
          <div class="col-md-3 justify-content-between">
            <div class='step-view center' :class="{active: currentStep >= 3, inactive: currentStep <= 2}" data-step='3' v-on:click='goToStep(3)' :disabled="currentStep === 3">
              <div class='icon-wrap'>
                <div class='icon'>3</div>
              </div>
            </div>
          </div>
          <div class="col-md-3 justify-content-between">
            <div class='step-view center' :class="{active: currentStep >= 4, inactive: currentStep <= 3}" data-step='4' v-on:click='goToStep(4)' :disabled="currentStep === 4">
              <div class='icon-wrap'>
                <div class='icon'>4</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class='headers'>
        <div class='step' v-if='currentStep < 4'>
          <h2>Anmeldung</h2>
        </div>
        <div class='step' v-else>
          <h2>Gastschüler</h2>
        </div>
      </div>
      <div class='steps'>
        <form action='/hostfamily_registrations' method='post' novalidate='true' v-on:submit.prevent='submit($event)'>
          <fieldset class='step' v-if='currentStep == 1'>
             <span v-if="osk_key != null">
              <legend class="step-header">Wir möchten Gastfamilie für den Orientierungs- und Sprachkurs werden!</legend>
            </span>
            <span v-else>
              <legend class="step-header">Wir möchten Gastfamilie werden!</legend>
            </span>
            <fieldset>
              <legend>Wir möchten <abbr title='Pflichtfeld'>*</abbr></legend>
              <div class='row'>
                <div class='col-md-3 col-sm-6'>
                  <label for='gender_3'>
                    <input name='gender' id='gender_3' ref='gender' type='checkbox' v-model='user_data.gender' v-on:click='resetCheckboxGender($event); disabled_gender = (disabled_gender + 1) % 2' value='no_preference'>
                    Geschlecht egal</label>
                </div>
                <div class='col-md-3 col-sm-6'>
                  <label for='gender_1'>
                    <input name='gender' id='gender_1' ref='gender' type='checkbox' v-model='user_data.gender' :disabled='disabled_gender == 1 ? true : false' v-validate='{ rules: "required", arg: "gender"}' value='male'>
                    einen Jungen</label>
                </div>
                <div class='col-md-3 col-sm-6'>
                  <label for='gender_2'>
                    <input name='gender' id='gender_2' ref='gender' type='checkbox' v-model='user_data.gender' :disabled='disabled_gender == 1 ? true : false' value='female'>
                    ein Mädchen</label>
                </div>
              </div>
              <div class='validation-error' v-if='errors.has("gender")'>
                <span class='error'>Bitte wählen Sie aus, welches Geschlecht Ihr Austauschschüler haben soll.</span>
              </div>
            </fieldset>

            <fieldset>
              <legend>im Alter von <abbr title='Pflichtfeld'>*</abbr></legend>
              <div class='row'>
                <div class='col-md-3 col-sm-6'>
                  <label for='age_1'>
                    <input name='age' id='age_1' ref='age' type='checkbox' v-model='user_data.age' v-validate='{ rules: "required", arg: "age" }' value='age_15'>
                    15 Jahre</label>
                </div>
                <div class='col-md-3 col-sm-6'>
                  <label for='age_2'>
                    <input name='age' id='age_2' ref='age' type='checkbox' v-model='user_data.age' value='age_16'>
                    16 Jahre</label>
                </div>
                <div class='col-md-3 col-sm-6'>
                  <label for='age_3'>
                    <input name='age' id='age_3' ref='age' type='checkbox' v-model='user_data.age' value='age_17'>
                    17 Jahre</label>
                </div>
                <div class='col-md-3 col-sm-6'>
                  <label for='age_4'>
                    <input name='age' id='age_4' ref='age' type='checkbox' v-model='user_data.age' value='age_18'>
                    18 Jahre</label>
                </div>
              </div>
              <div class='validation-error' v-if='errors.has("age")'>
                <span class='error'>Bitte wählen Sie aus, welches Alter Ihr Austauschschüler haben soll.</span>
              </div>
            </fieldset>

            <fieldset v-if="osk_key == null">
              <legend>Herkunft <abbr title='Pflichtfeld'>*</abbr></legend>
              <div class='row'>
                <div class='col-md-6 col-sm-6'>
                  <label for='origin_1'>
                    <input name='origin' id='origin_1' ref='origin' type='checkbox' v-model='user_data.origin' v-on:click='resetCheckboxOrigin($event); disabled_origin = (disabled_origin + 1) % 2' value='location_nopreference'>
                    Herkunft egal</label>
                </div>
                <div class='col-md-6 col-sm-6'>
                  <label for='origin_2'>
                    <input name='origin' id='origin_2' ref='origin' type='checkbox' v-model='user_data.origin' :disabled='disabled_origin == 1 ? true : false' value='europe'>
                    Europa</label>
                </div>
                <div class='col-md-6 col-sm-6'>
                  <label for='origin_3'>
                    <input name='origin' id='origin_3' ref='origin' type='checkbox' v-model='user_data.origin' :disabled='disabled_origin == 1 ? true : false' v-validate='{ rules: "required", arg:"origin"}' value='asia'>
                    Asien</label>
                </div>
                <div class='col-md-6 col-sm-6'>
                  <label for='origin_4'>
                    <input name='origin' id='origin_4' ref='origin' type='checkbox' v-model='user_data.origin' :disabled='disabled_origin == 1 ? true : false' value='latin_america'>
                    Lateinamerika</label>
                </div>
                <div class='col-md-6 col-sm-6'>
                  <label for='origin_5'>
                    <input name='origin' id='origin_5' ref='origin' type='checkbox' v-model='user_data.origin' :disabled='disabled_origin == 1 ? true : false' value='africa'>
                    Afrika</label>
                </div>
                <div class='col-md-6 col-sm-6'>
                  <label for='origin_6'>
                    <input name='origin' id='origin_6' ref='origin' type='checkbox' v-model='user_data.origin' :disabled='disabled_origin == 1 ? true : false' value='english'>
                    Muttersprache englisch</label>
                </div>
              </div>
              <div class='validation-error' v-if='errors.has("origin")'>
                <span class='error'>Bitte wählen Sie aus, woher Ihr Austauschschüler kommen soll.</span>
              </div>
              <label for='special_location_wish'>Besonderer Wunsch:
                <input name='special_location_wish' id='special_location_wish' type='text' v-model='user_data.special_location_wish' :disabled='disabled_origin == 1 ? true : false'>
              </label>
            </fieldset>
            <span v-if="osk_key == null">
              <fieldset >
                <legend>Zeitraum <abbr title='Pflichtfeld'>*</abbr></legend>
                <p>Die schönste und intensivste Zeit mit YFU haben Sie, wenn Sie Ihr Gastkind für ein ganzes Jahr aufnehmen. Sollte Ihnen dies aber nicht möglich sein, können Sie bei YFU auch für einen kürzeren Zeitraum Gastfamilie werden. Hier können
                  Sie
                  angeben, wie lange und ab wann Sie aufnehmen können.</p>
                <div class='row'>
                  <div class='col-md-6 col-sm-6'>
                    <label for='length_1'>
                      <input name='length' id='length_1' ref='length' type='checkbox' v-model='user_data.length' v-validate='{ rules: "required", arg: "length"}' value='twelve_months'>
                      ein Jahr</label>
                  </div>
                  <div class='col-md-6 col-sm-6'>
                    <label for='length_2'>
                      <input name='length' id='length_2' ref='length' type='checkbox' v-model='user_data.length' value='six_months'>
                      ein Halbjahr</label>
                  </div>
                  <div class='col-md-6 col-sm-6'>
                    <label for='length_3'>
                      <input name='length' id='length_3' ref='length' type='checkbox' v-model='user_data.length' value='three_months'>
                      drei Monate</label>
                  </div>
                </div>
                <div class='validation-error' v-if='errors.has("length")'>
                  <span class='error'>Bitte wählen Sie aus, wie lange Sie Ihren Austauschschüler aufnehmen wollen.</span>
                </div>
              </fieldset>
              <fieldset>
                <legend>ab <abbr title='Pflichtfeld'>*</abbr></legend>
                <div class='row'>
                  <div class='col-md-6 col-sm-6' v-for="(item, i) in season_programs_data.season_programs" v-bind:key="i">
                    <label :for='item.value'>
                      <input name='start' :id='item.value' ref='start' type='checkbox' v-model='user_data.start' v-validate='{rules: "required", arg: "start"}' :value='item.value'>
                      {{item.label}}
                    </label>
                  </div>
                </div>
                <div class='validation-error' v-if='errors.has("start")'>
                  <span class='error'>Bitte wählen Sie aus, ab wann Sie Ihren Austauschschüler aufnehmen wollen.</span>
                </div>
                <label for='other_areas'>Andere Zeiträume, für die wir Sie bei Bedarf ansprechen können:
                  <input name='other_areas' id='other_areas' type='text' v-model='user_data.other_areas'>
                  <i> {{season_programs_data.info_text}}</i>
                </label>
              
              </fieldset>
            </span>
          </fieldset>
          <fieldset class='step' v-if='currentStep == 2'>
            <legend class="step-header">Unsere Familie</legend>
            <fieldset>
              <legend>Unsere Adresse
              </legend>
              <div class='row'>
                <div class='col-md-6'>
                  <label for='street'>Straße/Hausnummer<abbr title='Pflichtfeld'>*</abbr>
                    <input name='street' id='street' ref='street' type='text' v-model='user_data.street' v-validate="'required'">
                  </label>
                  <div class='validation-error' v-if='errors.has("street")'>
                    <span>Bitte geben Sie Ihre Straße und Hausnummer ein.</span>
                  </div>
                </div>

                <!-- <div class='col-md-6'>
                  <label for='addressline_2'>Adresszusatz
                    <input name='addressline_2' id='addressline_2' ref='addressline_2' type='text' v-model='user_data.addressline_2'>
                  </label>
                </div> -->

                <div class='col-md-6'>
                  <label for='zip'>Postleitzahl<abbr title='Pflichtfeld'>*</abbr>
                    <input name='zip' id='zip' ref='zip' type='text' v-model='user_data.zip' v-validate="'required|digits:5'">
                  </label>
                  <div class='validation-error' v-if='errors.has("zip")'>
                    <span class='error'>Bitte geben Sie eine gültige Postleitzahl ein.</span>
                  </div>
                </div>
                <div class='col-md-6'>
                  <label for='city'>Ort<abbr title='Pflichtfeld'>*</abbr>
                    <input name='city' id='city' ref='city' type='text' v-model='user_data.city' v-validate='"required"'>
                  </label>
                  <div class='validation-error' v-if="errors.has('city')">
                    <span class='error'>Bitte geben Sie Ihren Wohnort ein.</span>
                  </div>
                </div>
                <div class='col-md-6'>
                  <label for='phone'>Telefonische Kontaktmöglichkeit<abbr title='Pflichtfeld'>*</abbr>
                    <input name='phone' id='phone' ref='phone' type='tel' v-model='user_data.phone' v-validate="{required: true, regex: /^[\d ()+-]+$/}">
                  </label>
                  <div class='validation-error' v-if="errors.has('phone')">
                    <span v-if="errors.first('phone') == 'The phone field is required'" class='error'> Bitte geben Sie ihre Telefonnummer ein.</span>
                    <span v-if="errors.first('phone') == 'The phone field format is invalid'" class='error'>Bitte geben Sie ihre Telefonnummer korrekt ein.</span>
                  </div>
                </div>
              </div>
              <div class='row'>
                <div class='col-md-6'>
                  <label for='email'>E-Mail<abbr title='Pflichtfeld'>*</abbr>
                    <input name='email' id='email' ref='email' type='email' v-model='user_data.email' v-validate="'required|email'">
                  </label>
                  <div class='validation-error' v-if='errors.has("email")'>
                    <span class='error'>Bitte geben Sie eine gültige E-Mail Adresse ein.</span>
                  </div>
                </div>
              </div>
            </fieldset>
            <div>
              <fieldset>
                <legend>Elternteil</legend>
                <div class='row'>
                  <div class='col-md-6'>
                    <div class='row'>
                      <div class='col-md-6 col-sm-6'>
                        <label for='parent_1_gender_m'>
                          <input name='parent_1_gender' id='parent_1_gender_m' ref='parent_1_gender' type='radio' v-model='user_data.parents.parent_1.gender' v-validate="{rules: 'required', arg: 'parent_1_gender'}" value='m'>
                          Herr<abbr title='Pflichtfeld'>*</abbr></label>
                      </div>
                      <div class='col-md-6 col-sm-6'>
                        <label for='parent_1_gender_f'>
                          <input name='parent_1_gender' id='parent_1_gender_f' ref='parent_1_gender' type='radio' v-model='user_data.parents.parent_1.gender' value='f'>
                          Frau<abbr title='Pflichtfeld'>*</abbr></label>
                      </div>
                    </div>
                    <div class='validation-error' v-if="errors.has('parent_1_gender')">
                      <span class='error'>Bitte geben Sie die Anrede ein.</span>
                    </div>
                  </div>
                </div>
                <div class='row'>
                  <div class='col-md-6'>
                    <label for='parent_1_lastname'>Nachname<abbr title='Pflichtfeld'>*</abbr>
                      <input name='parent_1_lastname' id='parent_1_lastname' ref='parent_1_lastname' type='text' v-model='user_data.parents.parent_1.lastname' v-validate="'required'">
                    </label>
                    <div class='validation-error' v-if="errors.has('parent_1_lastname')">
                      <span class='error'>Bitte geben Sie den Nachnamen ein.</span>
                    </div>
                  </div>
                  <div class='col-md-6'>
                    <label for='parent_1_firstname'>Vorname<abbr title='Pflichtfeld'>*</abbr>
                      <input name='parent_1_firstname' id='parent_1_firstname' ref='parent_1_firstname' type='text' v-model='user_data.parents.parent_1.firstname' v-validate="'required'">
                    </label>
                    <div class='validation-error' v-if="errors.has('parent_1_firstname')">
                      <span class='error'>Bitte geben Sie den Vornamen ein.</span>
                    </div>
                  </div>
                </div>
                <div class='row'>
                  <div class='col-md-6'>
                    <label for='parent_1_birthday'>Geburtsdatum<abbr title='Pflichtfeld'>*</abbr>
                      <input name='parent_1_birthday' id='parent_1_birthday' ref='parent_1_birthday' type='text' v-model='user_data.parents.parent_1.birthday' v-validate="`required|date_format:dd.MM.yyyy|date_between:01.01.1900,${date_minus_eighteen}`">
                    </label>
                    <div class='validation-error' v-if="errors.has('parent_1_birthday')">
                      <span class='error'>Bitte geben Sie das Geburtsdatum im Format TT.MM.JJJJ ein, ausserdem müssen Sie mindestes 18 Jahre sein.</span>
                    </div>
                  </div>
                  <div class='col-md-6'>
                    <label for='parent_1_occpuation'>Berufsbezeichnung (wenn möglich auf Englisch)<abbr title='Pflichtfeld'>*</abbr>
                      <input name='parent_1_occupation' id='parent_1_occpuation' ref='parent_1_occupation' type='text' v-model='user_data.parents.parent_1.occupation' v-validate="'required'">
                    </label>
                    <div class='validation-error' v-if="errors.has('parent_1_occupation')">
                      <span class='error'>Bitte geben Sie den Beruf ein.</span>
                    </div>
                  </div>
                  <div class='col-md-6'>
                    <label for='parent_1_mobile'>Telefon mobil
                      <input name='parent_1_mobile' id='parent_1_mobile' ref='parent_1_mobile' type='text' v-model='user_data.parents.parent_1.mobile' v-validate="{regex: /^[\d ()+-]+$/}">
                    </label>
                    <div class='validation-error' v-if="errors.has('parent_1_mobile')">
                      <span class='error'>Bitte geben Sie die Handynummer korrekt ein.</span>
                    </div>
                  </div>
                </div>
              </fieldset>
              <fieldset>
                <legend>Weiteres Elternteil</legend>
                <div class='row'>
                  <div class='col-md-6'>
                    <div class='row'>
                      <div class='col-md-6'>
                        <label for='parent_2_gender_m'>
                          <input name='parent_2_gender' id='parent_2_gender_m' ref='parent_2_gender' type='radio' v-model='user_data.parents.parent_2.gender' value='m'>
                          Herr
                        </label>
                      </div>
                      <div class='col-md-6'>
                        <label for='parent_2_gender_f'>
                          <input name='parent_2_gender' id='parent_2_gender_f' ref='parent_2_gender' type='radio' v-model='user_data.parents.parent_2.gender' value='f'>
                          Frau
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class='row'>
                  <div class='col-md-6'>
                    <label for='parent_2_lastname'>Nachname<abbr title='Pflichtfeld'>*</abbr>
                      <input name='parent_2_lastname' id="parent_2_lastname" ref='parent_2_lastname' type='text' v-model='user_data.parents.parent_2.lastname'>
                    </label>
                  </div>
                  <div class='col-md-6'>
                    <label for='parent_2_firstname'>Vorname<abbr title='Pflichtfeld'>*</abbr>
                      <input name='parent_2_firstname' id="parent_2_firstname" ref='parent_2_firstname' type='text' v-model='user_data.parents.parent_2.firstname'>
                    </label>
                  </div>
                </div>
                <div class='row'>
                  <div class='col-md-6'>
                    <label for='parent_2_birthday'>Geburtsdatum<abbr title='Pflichtfeld'>*</abbr></label>
                    <input name='parent_2_birthday' id='parent_2_birthday' ref='parent_2_birthday' type='text' v-model='user_data.parents.parent_2.birthday' v-validate="`date_format:dd.MM.yyyy|date_between:01.01.1900,${date_minus_eighteen}`">
                    <div class='validation-error' v-if="errors.has('parent_2_birthday')">
                      <span class='error'>Bitte geben Sie das Geburtsdatum im Format TT.MM.JJJJ ein, ausserdem müssen Sie mindestes 18 Jahre sein.</span>
                    </div>
                  </div>
                  <div class='col-md-6'>
                    <label for='parent_2_occpuation'>Berufsbezeichnung (wenn möglich auf Englisch)
                      <input name='parent_2_occupation' id='parent_2_occpuation' ref='parent_2_occupation' type='text' v-model='user_data.parents.parent_2.occupation'>
                    </label>
                  </div>
                  <div class='col-md-6'>
                    <label for='parent_2_mobile'>Telefon mobil
                      <input name='parent_2_mobile' id='parent_2_mobile' ref='parent_2_mobile' type='text' v-model='user_data.parents.parent_2.mobile' v-validate="{regex: /^[\d ()+-]+$/}">
                    </label>
                    <div class='validation-error' v-if="errors.has('parent_2_mobile')">
                      <span class='error'>Bitte geben Sie die Handynummer korrekt ein.</span>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <fieldset class="children">
              <legend>Kinder</legend>
              <a class='add-child btn-100 violet-btn' href="#!" v-on:click='addChild($event)'>
                Kind hinzufügen
              </a>
              <fieldset class='child' v-for="(child, index) in user_data.children" v-bind:key="child.index">
                <div class='row'>
                  <div class='col-md-12'>
                    <a class='pull-right remove' href="#!" v-on:click='removeChild($event, index)'></a>
                  </div>
                </div>
                <div class='child' :id="'child_' + index + '_collapse'">
                  <fieldset>
                    <legend>Persönliche Daten:</legend>
                    <div class='row'>
                      <div class='col-md-6'>
                        <label :for="'child_' + index + '_lastname'">
                          Nachname<abbr title='Pflichtfeld'>*</abbr>
                          <input :ref="'child_' + index + '_lastname'"
                          type='text'
                          :name="'child_' + index + '_lastname'"
                          :id="'child_' + index + '_lastname'" value="user_data.parents.parent_2.lastname"
                          v-model='user_data.children[index].child_lastname'
                          v-validate="'required'">
                        </label>
                        <div class='validation-error' v-if="errors.has('child_' + index + '_lastname')">
                          <span class='error'>Bitte geben Sie den Nachnamen ein.</span>
                        </div>
                      </div>
                      <div class='col-md-6'>
                        <label :for="'child_' + index + '_firstname'">Vorname<abbr title='Pflichtfeld'>*</abbr>
                          <input :ref="'child_' + index + '_firstname'"
                          type='text'
                          :name="'child_' + index + '_firstname'"
                          :id="'child_' + index + '_firstname'"
                          v-model='user_data.children[index].child_firstname'
                          v-validate="'required'">
                        </label>
                        <div class='validation-error' v-if="errors.has('child_' + index + '_firstname')">
                          <span class='error'>Bitte geben Sie den Vornamen ein.</span>
                        </div>
                      </div>
                      <div class='col-md-6'>
                        <label :for="'child_' + index + '_birthday'">Geburtsdatum<abbr title='Pflichtfeld'>*</abbr>
                          <input placeholder="DD.MM.YYYY" :ref="'child_' + index + '_birthday'" type='text' :name="'child_' + index + '_birthday'" :id="'child_' + index + '_birthday'" v-model='user_data.children[index].birthday' v-validate="`required|date_format:dd.MM.yyyy|date_between:01.01.1900,${date_today}`">
                        </label>
                        <div class='validation-error' v-if="errors.has('child_' + index + '_birthday')">
                          <span class='error'>Bitte geben Sie das Geburtsdatum im Format TT.MM.JJJJ ein.</span>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                  <fieldset>
                    <legend>Geschlecht<abbr title='Pflichtfeld'>*</abbr></legend>
                    <div class='row'>
                      <div class='col-md-6'>
                        <label :for="'child_' + index + '_gender_1'">
                          <input :ref="'child_' + index + '_gender'" type='radio' :name="'child_' + index + '_gender'" :id="'child_' + index + '_gender_1'" v-model='user_data.children[index].gender' v-validate="'required'" value='m'>
                          männlich</label>
                      </div>
                      <div class='col-md-6'>
                        <label :for="'child_' + index + '_gender_2'">
                          <input :ref="'child_' + index + '_gender'" type='radio' :name="'child_' + index + '_gender'" :id="'child_' + index + '_gender_2'" v-model='user_data.children[index].gender' value='w'>
                          weiblich</label>
                      </div>
                    </div>
                    <div class='validation-error' v-if="errors.has('child_' + index + '_gender')">
                      <span class='error'>Bitte geben Sie das Geschlecht an.</span>
                    </div>
                  </fieldset>
                  <fieldset>
                    <legend>Leben zu Hause?<abbr title='Pflichtfeld'>*</abbr></legend>
                    <div class='row'>
                      <div class='col-md-6'>
                        <label :for="'child_' + index + '_lives_at_home_1'">
                          <input :ref="'child_' + index + '_lives_at_home'" type='radio' :name="'child_' + index + '_lives_at_home'" :id="'child_' + index + '_lives_at_home_1'" v-model='user_data.children[index].lives_at_home' v-validate="'required'"
                            value='true'>
                          ja</label>
                      </div>
                      <div class='col-md-6'>
                        <label :for="'child_' + index + '_lives_at_home_2'">
                          <input :ref="'child_' + index + '_lives_at_home'" type='radio' :name="'child_' + index + '_lives_at_home'" :id="'child_' + index + '_lives_at_home_2'" v-model='user_data.children[index].lives_at_home' value='false'>
                          nein</label>
                      </div>
                    </div>
                    <div class='validation-error' v-if="errors.has('child_' + index + '_lives_at_home')">
                      <span class='error'>Bitte geben Sie den Wohnort Ihres Kindes an.</span>
                    </div>
                  </fieldset>
                </div>
              </fieldset>
            </fieldset>
            <fieldset>
              <legend>Haustiere</legend>
              <label for="other_pets">Wir haben Haustiere, nämlich:
                <input name='other_pets' id='other_pets' type='text' v-model='user_data.other_pets'>
              </label>
            </fieldset>
          </fieldset>
          <fieldset class='step'  v-if='currentStep == 3'>
            <legend class="step-header">Über uns</legend>
            <fieldset>
              <legend>Wohnlage<abbr title='Pflichtfeld'>*</abbr></legend>
              <div class='row'>
                <div class='col-md-3'>
                  <label for="living_area_urban_central">
                    <input name='living_area' id="living_area_urban_central" ref='living_area' type='radio' v-model='user_data.living_area' v-validate="{ rules: 'required', arg: 'living_area' }" value='urban_central'>
                    Stadtzentrum</label>
                </div>
                <div class='col-md-3'>
                  <label for="living_area_suburban">
                    <input name='living_area' id="living_area_suburban" ref='living_area' type='radio' v-model='user_data.living_area' value='suburban'>
                    Stadtrand</label>
                </div>
                <div class='col-md-3'>
                  <label for="living_area_town">
                    <input name='living_area' id="living_area_town" ref='living_area' type='radio' v-model='user_data.living_area' value='town'>
                    Kleinstadt</label>
                </div>
                <div class='col-md-3'>
                  <label for="living_area_rural">
                    <input name='living_area#' id="living_area_rural" ref='living_area' type='radio' v-model='user_data.living_area' value='rural'>
                    Dorf/Land</label>
                </div>
              </div>
              <div class='validation-error' v-if="errors.has('living_area')">
                <span class='error'>Bitte wählen Sie eine Wohnlage aus.</span>
              </div>
            </fieldset>
            <fieldset>
              <legend>Rauchen<abbr title='Pflichtfeld'>*</abbr></legend>
              <div class="row">
                <div class='col-6'>
                  <label for="smoke_yes">
                    <input ref='smoke' name='smoke' id="smoke_yes" type='radio' v-model='user_data.smoke' v-validate="{ rules: 'required', arg: 'smoke' }" value='yes'>
                    ja, überall.</label>
                </div>
                <div class='col-md-6'>
                  <label for="smoke_no">
                    <input ref='smoke' name='smoke' id="smoke_no" type='radio' v-model='user_data.smoke' value='no'>
                    nein</label>
                </div>
                <div class='col-md-6'>
                  <label for="smoke_outside">
                    <input ref='smoke' name='smoke' id="smoke_outside" type='radio' v-model='user_data.smoke' value='outside'>
                    ja, aber nur außerhalb des Hauses.</label>
                </div>
              </div>
              <div class='validation-error' v-if="errors.has('smoke')">
                <span class='error'>Bitte geben Sie an, ob in Ihrem Haushalt geraucht wird.</span>
              </div>
            </fieldset>
            <fieldset>
              <legend>Fremdsprachen-Kenntnisse</legend>
              <p class="legend_description">
                <b>Bitte beachten Sie:</b>
                Fremdsprachenkenntnisse sind nicht zwingend erforderlich, können aber in der Anfangszeit hilfreich sein. Generell werden die Gastfamilien angehalten, Deutsch mit den Austauschschülern zu sprechen, sodass diese die Sprache so schnell
                wie
                möglich erlernen können.
              </p>
              <label name="languages" for="languages">Fremdsprachen:</label>
              <v-select max-height="200px" multiple label="name" v-model="user_data.languages" :options="optionsLanguage" placeholder="Wählen Sie Ihre Fremdsprachen...">
                <template slot="option" slot-scope="option">
                  {{ option.name }}
                </template>
                <span slot="no-options">Fremdsprache nicht vorhanden.</span>
              </v-select>
            </fieldset>
            <fieldset>
              <legend>Interessen und Hobbies</legend>
              <p>Wir möchten gern, dass Ihr zukünftiges Gastkind besonders gut in Ihre Familie passt. Die Interessen und Hobbies von Ihnen wie auch von den Jugendlichen dienen uns dabei als wichtige Anhaltspunkte. Sie können gern auch die Interessen
                einzelner Familienmitglieder aufführen.</p>
              <label for='hobbies'>
                <textarea name='hobbies' id='hobbies' v-model='user_data.hobbies'></textarea>
              </label>
            </fieldset>
            <fieldset v-if="osk_key == null">
              <legend>Schulempfehlung</legend>
              <p>Sie kennen sich in Ihrer Gegend am besten aus. Daher freuen wir uns über eine Schulempfehlung für den Austauschschüler. Die Jugendlichen sind verpflichtet, eine allgemeinbildende Schule zu besuchen. In den meisten Fällen ist dies
                ein
                Gymnasium oder eine Gesamtschule, aber auch eine Realschule oder - in Einzelfällen – eine Privatschule ist möglich.</p>
              <label for='school_name'>Name:
                <input name='school_name' id='school_name' type='text' v-model='user_data.school_name'>
              </label>
              <label for='school_address'>
                Adresse der Schule:
                <textarea name='school_address' id='school_address' v-model='user_data.school_address'></textarea>
              </label>
            </fieldset>
          </fieldset>
          <fieldset class='step' v-if='currentStep == 4'>
            <legend class="step-header">Wir und YFU</legend>
            <fieldset>
              <legend>Besuch des ehrenamtlichen Mitarbeiters:<abbr title='Pflichtfeld'>*</abbr></legend>
              <p>YFU ist es wichtig, alle Gastfamilien im persönlichen Gespräch näher kennenzulernen. Dies gibt auch Ihnen die Möglichkeit, noch weitere Fragen zu stellen. Eine ehrenamtliche Mitarbeiterin oder ein ehrenamtlicher Mitarbeiter aus
                Ihrer
                Region wird Sie – nach vorheriger Terminabsprache – zu Hause besuchen.
                Bitte geben Sie hier mögliche Zeiträume für einen Besuch an (z.B. Montag – Freitag ab 18 Uhr, Sonntags ganztägig usw.).</p>
              <label for='visit'>Besuch des ehrenamtlichen Mitarbeiters:
                <textarea name='visit' ref='visit' id="visit" v-model='user_data.visit' v-validate="'required'"></textarea>
              </label>
              <div class='validation-error' v-if="errors.has('visit')">
                <span class='error'>Bitte beantworten Sie die Frage.</span>
              </div>
            </fieldset>
            <fieldset>
              <legend>Hatten Sie bereits Kontakt mit YFU?</legend>
              <div class='row'>
                <div class='col-md-6'>
                  <label for='yfu_contact_yes'>
                    <input name='yfu_contact' id="yfu_contact_yes" type='radio' v-model='user_data.yfu_contact' value='yes'>
                    Ja</label>
                </div>
                <div class='col-md-6'>
                  <label for='yfu_contact_no'>
                    <input name='yfu_contact' id="yfu_contact_no" type='radio' v-model='user_data.yfu_contact' value='no'>
                    Nein</label>
                </div>
              </div>
              <fieldset class='yfu-contact' v-if="user_data.yfu_contact == 'yes'">
                <legend>Ja:</legend>
                <label for='year_student'>
                  <input name='year_student' id='year_student' type='checkbox' v-model='user_data.year_student' value='true'>
                  Aufnahme eines Jahresprogramm-Schülers</label>
                <div class='explanation' v-if='user_data.year_student'>
                  <label for='year_student_explanation'>im Jahr
                    <input name='year_student_explanation' id='year_student_explanation' type='text' v-model='user_data.year_student_explanation'>
                  </label>
                </div>
                <br>
                <label for='short_term_student'>
                  <input name='short_term_student' id='short_term_student' type='checkbox' v-model='user_data.short_term_student' value='true'>
                  Aufnahme eines Kurzprogramm-Schülers</label>
                <div class='explanation' v-if='user_data.short_term_student'>
                  <label for='short_term_student_explanation'>im Jahr
                    <input name='short_term_student_explanation' id='short_term_student_explanation' type='text' v-model='user_data.short_term_student_explanation'>
                  </label>
                </div>
                <br>
                <label for='language_course_student'>
                  <input name='language_course_student' id='language_course_student' type='checkbox' v-model='user_data.language_course_student' value='true'>
                  Aufnahme eines Sprachkurs-Schülers</label>
                <div class='explanation' v-if='user_data.language_course_student'>
                  <input name='language_course_student_explanation' id='language_course_student_explanation' type='text' v-model='user_data.language_course_student_explanation'>
                  <label for='language_course_student_explanation'>im Jahr</label>
                </div>
                <br>
                <label for='own_kid_on_exchange'>
                  <input name='own_kid_on_exchange' id='own_kid_on_exchange' type='checkbox' v-model='user_data.own_kid_on_exchange' value='true'>
                  eigenes Kind mit YFU im Austauschjahr</label>
                <div class='explanation' v-if='user_data.own_kid_on_exchange'>
                  <label for='own_kid_on_exchange_explanation'>im Jahr</label>
                  <input name='own_kid_on_exchange_explanation' id='own_kid_on_exchange_explanation' type='text' v-model='user_data.own_kid_on_exchange_explanation'>
                </div>
                <br>
                <label for='parent_on_exchange'>
                  <input name='parent_on_exchange' id='parent_on_exchange' type='checkbox' v-model='user_data.parent_on_exchange' value='true'>
                  Elternteil mit YFU im Austauschjahr</label>
                <div class='explanation' v-if='user_data.parent_on_exchange'>
                  <label for='parent_on_exchange_exaplanation'>im Jahr</label>
                  <input name='parent_on_exchange_exaplanation' id='parent_on_exchange_exaplanation' type='text' v-model='user_data.parent_on_exchange_explanation'>
                </div>
                <br>
                <label for='volunteer_work'>
                  <input name='volunteer_work' id='volunteer_work' type='checkbox' v-model='user_data.volunteer_work' value='true'>
                  ehrenamtliche Mitarbeit</label>
              </fieldset>
              <fieldset class='non-yfu-contact' v-if="user_data.yfu_contact == 'no'" v-on:click="onChange($event)">
                <fieldset>
                  <legend>Wie sind Sie auf YFU aufmerksam geworden?</legend>
                  <label for='newspaper'>
                    <input name='newspaper' id='newspaper' type='checkbox' v-model='user_data.newspaper' value='true'>
                    aus der Zeitung</label>
                  <br>
                  <label for='poster_flyer'>
                    <input name='poster_flyer' id='poster_flyer' type='checkbox' v-model='user_data.poster_flyer' value='true'>
                    durch Plakate/Flyer</label>
                  <br>
                  <label for="friends">
                    <input name='friends' id='friends' type='checkbox' v-model='user_data.friends' value='true'>
                    durch Freunde/Bekannte</label>
                  <br>
                  <label for='info'>
                    <input name='info' id='info' type='checkbox' v-model='user_data.info' value='true'>
                    durch eine Informationsveranstaltung</label>
                  <br>
                  <label for='internet'>
                    <input name='internet' id='internet' type='checkbox' v-model='user_data.internet' :disabled='disabled_internet == 1 ? true : false' value='true'>
                    im Internet, nämlich:</label>
                  <div class='internet-inputs'>
                    <label for='website'>
                      <input name='website' id='website' type='checkbox' v-model='user_data.website' value='true'>
                      Suchmaschine</label>
                    <label for='facebook'>
                      <input name='facebook' id='facebook' type='checkbox' v-model='user_data.facebook' value='true'>
                      Facebook</label>
                    <label for='internet_other'>
                      <input name='internet_other' id='internet_other' type='checkbox' v-model='user_data.internet_other' value='true'>
                      Andere Seiten</label>
                    <div class='explanation'>
                      <label for='internet_other_explanation'>Welche?
                        <input name='internet_other_explanation' id='internet_other_explanation' type='text' v-model='user_data.internet_other_explanation'>
                      </label>
                    </div>
                  </div>
                  <br>
                  <label for='club'>
                    <input name='club' id='club' type='checkbox' v-model='user_data.club' value='true'>
                    über einen Verein</label>
                  <span>
                    <input name='club_name' id='club_name' type='text' v-model='user_data.club_name'>
                  </span>
                  <br>
                  <br>
                  <label for='other'>
                    <input name='other' id='other' type='checkbox' v-model='user_data.other' value='true'>
                    Sonstiges</label>
                  <span>
                    <input name='other_description' id='other' type='text' v-model='user_data.other_description'>
                  </span>
                  <br>
                  <br>
                </fieldset>
              </fieldset>
            </fieldset>
            <div class="send-wrap">
              <input name="submit" class='btn-100 orange-btn' type='submit' value='Abschicken'>
            </div>
            <div class='validation-error' v-if="errors.has('submit')">
              <span class="center">Es ist leider zu einem technischen Problem gekommen, bitte versuchen Sie es noch einmal.</span>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
    <div class="col-12" v-else>
      <div class="error-container">
        <p class="center-text">
          <span v-if="submitErrors">
            <i class="fa fa-2x fa-frown"></i>
          </span>
          <span v-else>
            <i class="fa fa-2x fa-smile"></i>
          </span>
          <br>
          Vielen Dank für Ihre Meldung!
          <br>
          <span v-if="submitErrors">
            Ein Fehler ist aufgetreten!
            <br>
            <br>
            <button class="btn-100 orange-btn center" v-on:click='currentStep = 1, sent = false'> zurück zur ersten Seite..</button>
          </span>
        </p>
      </div>
    </div>
    <div>
      <div class='row'>
        <div class='col-md-6' v-if='currentStep > 1' v-show="!sent">
          <button class='btn-100 violet-btn' v-on:click='previousStep'>
            Zurück
          </button>
        </div>
        <div class='col-md-6' v-if='currentStep < 4' v-show="!sent">
          <button class='btn-100 orange-btn' :class="currentStep > 1 ? 'pull-right' : ''" v-on:click='nextStep'>
            Weiter
          </button>
        </div>
      </div>
      <i v-show="!sent">Pflichtfeld<abbr>*</abbr></i>
    </div>
  </div>
</span>
</template>
<script>
export default {
  data() {
    return {
      element_id: null,
      backendData: null,
      season_programs_data: 0,
      success_page_id: null,
      osk_key: null,
      loaded: false,
      sent: false,
      submitErrors: false,
      apiErrors: {
        errorBool: false,
        errorMsgs: [],
      },
      disabled_origin: 0,
      disabled_smoke: 0,
      disabled_gender: 0,
      disabled_internet: 0,
      items: [],
      currentStep: 0,
      selected: null,
      date_minus_eighteen: null,
      date_today: null,
      optionsStations: [],
      optionsLanguage: [],
      searchInput: [],
      user_data: {
        gender: [],
        age: [],
        origin: [],
        length: [],
        start: [],
        special_location_wish: '',
        other_areas: '',
        street: '',
        addressline_2: '',
        zip: '',
        city: '',
        phone: '',
        email: '',
        parents: {
          parent_1: {
            gender: '',
            lastname: '',
            firstname: '',
            birthday: '',
            occupation: '',
            mobile: ''
          },
          parent_2: {
            gender: '',
            lastname: '',
            firstname: '',
            birthday: '',
            occupation: '',
            mobile: ''
          }
        },
        children: [],
        other_pets: '',
        living_area: '',
        population: '',
        smoke: '',
        smoke_outside: false,
        smoke_inside: false,
        languages: [],
        hobbies: '',
        train_stations: [],
        school_name: '',
        school_address: '',
        subsidy_requested: false,
        visit: '',
        yfu_contact: '',
        year_student: false,
        year_student_explanation: '',
        short_term_student: false,
        short_term_student_explanation: '',
        language_course_student: false,
        language_course_student_explanation: '',
        own_kid_on_exchange: false,
        own_kid_on_exchange_explanation: '',
        parent_on_exchange: false,
        parent_on_exchange_explanation: '',
        volunteer_work: false,
        newspaper: false,
        poster_flyer: false,
        friends: false,
        info: false,
        internet: false,
        website: false,
        facebook: false,
        internet_other: false,
        internet_other_explanation: '',
        club: false,
        club_name: '',
        other: false,
        other_description: ''
      }
    }
  },
  created() {
    this.currentStep = 1;
    //apis init
    // if osk
    this.$http.get('/api/languages.json').then(resp => {
      this.optionsLanguage = resp.data.items;
    }, response => {
      // show an error on the page
      this.apiErrors.errorBool = true;
      this.apiErrors.errorMsgs.push({
        status: response.status,
        error: `languages api is not responding correctly STATUS: ${response.status}`
      });
    });
    // else
    //countries ajax
    this.$http.get('/api/program_seasons.json').then(resp => {
      this.season_programs_data = resp.body;
    }, response => {
      // // show an error on the page
      this.apiErrors.errorBool = true;
      this.apiErrors.errorMsgs.push({
        status: response.status,
        error: `languages api is not responding correctly STATUS: ${response.status}`
      });
    });

  },
  mounted() {
    //find osk_key in URL Params
    let url = new URL(window.location.href);
    let osk_param = url.searchParams.get("osk_key");
    this.osk_key = osk_param;
    //when component is mounted to Dom get and push data-rel to data prop
    let dataset = Object.assign({},this.$el.parentElement.dataset);
    this.backendData = dataset;
    this.element_id = dataset.elementId;
    this.date_today = dataset.dateToday;
    this.date_minus_eighteen = dataset.dateOfBirthValidator;
    this.success_page_id = dataset.successPageId;
    //render formstep
    this.renderStep();
    //set loaded bool to show form
    this.loaded = true;

    // testing ga4
    if(typeof gtag === "function") {
      gtag ("event", "Gastgeberbogen geöffnet", {
        content_type: "Gastgeberbogen geöffnet",
      });
    }
  },
  components: {
    "v-select": () => import('vue-select')
  },
  methods: {
    onChange: function(el) {
      if (el.target.checked) this.checked = true;
    },
    resetCheckboxGender($event) {
      this.user_data.gender = [];
      if ($event.target.checked) this.user_data.gender.push($event.target.value);
    },
    resetCheckboxOrigin($event) {
      this.user_data.origin = [];
      this.user_data.special_location_wish = [];
      if ($event.target.checked) this.user_data.origin.push($event.target.value);
    },
    nextStep: function() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          this.scrollToError();
          return;
        }
        this.currentStep += 1;
        this.renderStep();
      }).catch(() => {
        console.log('error on validation');
      });
    },
    previousStep: function() {
      this.currentStep -= 1;
      this.renderStep();
    },
    goToStep: function(stepnumber) {
      if (this.currentStep < stepnumber) {
        this.$validator.validateAll().then((result) => {
          if (!result) {
            this.scrollToError();
            return;
          }
          this.currentStep = stepnumber;
          this.renderStep();
        }).catch(() => {
          console.log('error on validation');
        });
      } else {
        this.currentStep = stepnumber;
        this.renderStep();
      }
    },
    renderStep: function() {
      // google analytics 4
      // if(typeof gtag === "function") {
      //   gtag("event", "Gastgeberbogen Schritt", {
      //     content_type: "Gastgeberbogen Schritt " + this.currentStep,
      //   });
      // }
      this.$el.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "center"
      });
    },
    addChild: function($event) {
      this.user_data.children.push({
        child_firstname: '',
        child_lastname: this.user_data.parents.parent_1.lastname,
        birthday: '',
        gender: '',
        lives_at_home: null
      })
    },
    removeChild: function($event, childIndex) {
      this.user_data.children.splice(childIndex, 1);
    },
    scrollToError: function() {
      const firstField = Object.keys(this.errors.collect())[0];
      this.$refs[`${firstField}`].scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center"
      });
    },
    submit: function($event) {
      // prevent enter submit form.
      if ($event.keyCode == 13) {
        $event.preventDefault();
        return false;
      } else {
        this.$validator.validateAll().then(() => {
          this.submitToBackend();
        });
      }
    },
    trackSubmit: function() {
      // google analytics 4
      if(typeof gtag === "function") {
        gtag("event", "Gastgeberbogen abgeschickt", {
          content_type: "Gastgeberbogen abgeschickt"
        });
      }

      if (window.fbq != undefined) {
        fbq('track', 'completeHostFamilyRegistrationWithFacebook');
      }
      //  microsoft ad tracking
      if(window.uetq != undefined) {
        window.uetq = window.uetq || []; 
        window.uetq.push('event', 'gfsignup', {});
      }
    },
    submitToBackend: function() {
      var data = this.prepareDataForSubmission();
      this.sent = true;
      this.loaded = false
      this.$http.post('/hostfamily_registrations.json',
        data
      ).then(resp => {
        // get status
        resp.status;
        // get status text
        resp.statusText;
        // get 'Expires' header
        resp.headers.get('Expires');
        // get body data
        this.submitErrors = false;
        this.loaded = false;
        this.trackSubmit();
        window.location.replace(resp.body['url']);
      }, response => {
        this.submitErrors = true;
        this.loaded = true;
      });
    },
    prepareDataForSubmission: function() {
      var languages_data_parse = this.user_data.languages.map((el) => el.id);
      var train_stations_data_parse = this.user_data.train_stations.map(el => el.id);
      var return_data = {
        authenticity_token: $('meta[name="csrf-token"]').prop('content'),
        success_page_id: this.success_page_id,
        data: {
          special_location_wish: this.user_data.special_location_wish,
          other_areas: this.user_data.other_areas,
          street: this.user_data.street,
          addressline_2: this.user_data.addressline_2,
          zip: this.user_data.zip,
          city: this.user_data.city,
          phone: this.user_data.phone,
          email: this.user_data.email,
          other_pets: this.user_data.other_pets,
          living_area: this.user_data.living_area,
          population: this.user_data.population,
          hobbies: this.user_data.hobbies,
          school_name: this.user_data.school_name,
          school_address: this.user_data.school_address,
          subsidy_requested: false,
          visit: this.user_data.visit,
          yfu_contact: this.user_data.yfu_contact,
          year_student: this.user_data.year_student,
          year_student_explanation: this.user_data.year_student_explanation,
          short_term_student: this.user_data.short_term_student,
          short_term_student_explanation: this.user_data.short_term_student_explanation,
          language_course_student: this.user_data.language_course_student,
          language_course_student_explanation: this.user_data.language_course_student_explanation,
          own_kid_on_exchange: this.user_data.own_kid_on_exchange,
          own_kid_on_exchange_explanation: this.user_data.own_kid_on_exchange_explanation,
          parent_on_exchange: this.user_data.parent_on_exchange,
          parent_on_exchange_exaplanation: this.user_data.parent_on_exchange_explanation,
          volunteer_work: this.user_data.volunteer_work,
          newspaper: this.user_data.newspaper,
          poster_flyer: this.user_data.poster_flyer,
          friends: this.user_data.friends,
          info: this.user_data.info,
          internet: this.user_data.internet,
          website: this.user_data.website,
          facebook: this.user_data.facebook,
          internet_other: this.user_data.internet_other,
          internet_other_explanation: this.user_data.internet_other_explanation,
          club: this.user_data.club,
          club_name: this.user_data.club_name,
          other: this.user_data.other,
          other_description: this.user_data.other_description,
          parents: this.user_data.parents,
          children: this.user_data.children,
          train_stations: train_stations_data_parse,
          languages: languages_data_parse,
          male: false,
          female: false,
          gender_nopreference: false,
          age_15: false,
          age_16: false,
          age_17: false,
          age_18: false,
          asia: false,
          latin_america: false,
          europe: false,
          africa: false,
          location_nopreference: false,
          english: false,
          twelve_months: false,
          six_months: false,
          three_months: false,
          summer_current: false,
          summer_next: false,
          winter_current: false,
          winter_next: false,
          smoke_outside: false,
          smoke_inside: false,
          osk_key: this.osk_key
        }
      }

      ///new vanilla version

      const user_data_entries = Object.entries(this.user_data);
      for (const [key, value] of user_data_entries) {
        if (key === 'gender' || key === 'age' || key === 'origin' || key === 'length' || key === 'start') {
          value.forEach((item) => {
            return_data['data'][item] = true
          });
        }
      }

      if (this.user_data.smoke == "yes") {
        return_data['data']['smoke_outside'] = true;
        return_data['data']['smoke_inside'] = true;
      }

      if (this.user_data.smoke == "yes") {
        return_data['data']['smoke_outside'] = true;
      }
      return return_data;

    }
  }
};
</script>

<style scoped lang="scss">

</style>
