"use strict"
<template>
  <div class="col-12" v-if="!loaded">
    <div class="loading">
      <i class="fas fa-circle-notch fa-2x fa-spin"></i>
    </div>
  </div>
  <div class="col-12" v-else-if="apiErrors.errorBool">
    <div class="error-container">
      <p class="center-text">
        <i class="fa fa-2x fa-frown"></i>
        <br />
        <br />Es ist leider ein Fehler aufgetreten. Bitte versuch die Seite neu
        zu laden.
      </p>
    </div>
  </div>
  <div class="form-wrap" v-else>
    <div class="form-inner-wrap" v-if="!sent">
      <form
        v-on:submit.prevent="validateBeforeSubmit('create_interest', formData)"
        novalidate="true"
        class="yfu_form applicant_form"
        id="interestform"
      >
        <fieldset>
          <div class="row">
            <div class="col-md-6 col-sm-6">
              <label
                :title="backendData.programSeasonHint"
                for="program_season_name"
                name="program_seasons"
              >
                Programmjahr
                <abbr>*</abbr>
                <div class="pull-right">
                  <a
                    class="hint-btn"
                    data-toggle="collapse"
                    href="#hint_program_seasons"
                    role="hint_program_seasons"
                    aria-expanded="false"
                    aria-controls="hint_program_seasons"
                  >
                    <i class="far fa-question-circle"></i>
                  </a>
                </div>
                <div class="clearfix"></div>
                <div class="hint collapse" id="hint_program_seasons">
                  {{ backendData.programSeasonHint }}
                </div>
                <v-select
                  max-height="200px"
                  id="program_seasons"
                  label="program_season_name"
                  name="program_season_name"
                  v-model="formData.program_season"
                  :options="program_type_seasons"
                  placeholder="Wähle Dein Programm..."
                  class="noclear"
                >
                  <template slot="option" slot-scope="option">{{
                    option.program_season_name
                  }}</template>
                  <span slot="no-options">Programmjahr (Bitte wählen)</span>
                </v-select>
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-sm-6">
              <label>
                Vorname
                <abbr>*</abbr>
                <div
                  v-bind:class="
                    !errors.has('firstname') &&
                    !formData.person_attributes.firstname == ''
                      ? 'has-success'
                      : errors.has('firstname')
                      ? 'has-error'
                      : ''
                  "
                >
                  <input
                    ref="firstname"
                    type="text"
                    v-model="formData.person_attributes.firstname"
                    name="firstname"
                    placeholder="Bitte gib deinen Vornamen ein."
                    v-validate="'required'"
                  />
                </div>
              </label>
              <div class="validation-error" v-show="errors.has('firstname')">
                <p class="error">Bitte gib deinen Vornamen ein.</p>
              </div>
            </div>

            <div class="col-md-6 col-sm-6">
              <label>
                Nachname
                <abbr>*</abbr>
                <div
                  v-bind:class="
                    !errors.has('lastname') &&
                    !formData.person_attributes.lastname == ''
                      ? 'has-success'
                      : errors.has('lastname')
                      ? 'has-error'
                      : ''
                  "
                >
                  <input
                    ref="lastname"
                    type="text"
                    v-model="formData.person_attributes.lastname"
                    name="lastname"
                    placeholder="Bitte gib deinen Nachnamen ein."
                    v-validate="'required'"
                  />
                </div>
              </label>
              <div class="validation-error" v-if="errors.has('lastname')">
                <p class="error">Bitte gib deinen Nachnamen ein.</p>
              </div>
            </div>
            <div class="col-md-12">
              <label>
                Straße und Hausnummer
                <abbr>*</abbr>
                <div
                  v-bind:class="
                    !errors.has('street') &&
                    !formData.person_attributes.address_attributes
                      .addressline_1 == ''
                      ? 'has-success'
                      : errors.has('street')
                      ? 'has-error'
                      : ''
                  "
                >
                  <input
                    ref="street"
                    type="text"
                    v-model="
                      formData.person_attributes.address_attributes
                        .addressline_1
                    "
                    name="street"
                    placeholder="Straße und Hausnummer eingeben"
                    v-validate="'required'"
                  />
                </div>
              </label>
              <div class="validation-error" v-if="errors.has('street')">
                <p class="error">Bitte gib deine Straße und Hausnummer ein.</p>
              </div>
              <label>
                Adresszusatz
                <input
                  ref="additional_address_info"
                  type="text"
                  v-model="
                    formData.person_attributes.address_attributes.addressline_2
                  "
                  name="additional_address_info"
                  placeholder="Adresszusatz eingeben"
                />
              </label>
            </div>
            <div class="col-md-4">
              <label>
                Postleitzahl
                <abbr>*</abbr>
                <div
                  v-bind:class="
                    !errors.has('zip') &&
                    !formData.person_attributes.address_attributes.zip == ''
                      ? 'has-success'
                      : errors.has('zip')
                      ? 'has-error'
                      : ''
                  "
                >
                  <input
                    ref="zip"
                    type="text"
                    v-model="formData.person_attributes.address_attributes.zip"
                    name="zip"
                    placeholder="Postleitzahl eingeben"
                    v-validate="'required|digits:5'"
                  />
                </div>
              </label>
              <div class="validation-error" v-if="errors.has('zip')">
                <p class="error">Bitte gib deine Postleitzahl ein.</p>
              </div>
            </div>
            <div class="col-md-8">
              <label>
                Ort
                <abbr>*</abbr>
                <div
                  v-bind:class="
                    !errors.has('city') &&
                    !formData.person_attributes.address_attributes.city == ''
                      ? 'has-success'
                      : errors.has('city')
                      ? 'has-error'
                      : ''
                  "
                >
                  <input
                    ref="city"
                    type="text"
                    v-model="formData.person_attributes.address_attributes.city"
                    name="city"
                    placeholder="Wohnort eingeben"
                    v-validate="'required'"
                  />
                </div>
              </label>
              <div class="validation-error" v-if="errors.has('city')">
                <p class="error">Bitte gib deinen Wohnort ein.</p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6 col-sm-6">
              <label for="countries_id" name="countries">
                Land deines Wohnortes
                <abbr>*</abbr>
                <v-select
                  max-height="200px"
                  id="countries_id"
                  v-model="
                    formData.person_attributes.address_attributes.country
                  "
                  label="name"
                  :options="countries"
                  placeholder="Land wählen"
                  class="noclear"
                >
                  <template slot="option" slot-scope="option">{{
                    option.name
                  }}</template>
                  <span slot="no-options">Land nicht vorhanden.</span>
                </v-select>
              </label>
            </div>
            <div class="col-md-6 col-sm-6">
              <label>
                Telefon
                <abbr>*</abbr>
                <div class="pull-right">
                  <a
                    class="hint-btn"
                    data-toggle="collapse"
                    href="#hint_phone"
                    role="hint_phone"
                    aria-expanded="false"
                    aria-controls="hint_phone"
                  >
                    <i class="far fa-question-circle"></i>
                  </a>
                </div>
                <div class="clearfix"></div>
                <div id="hint_phone" class="hint collapse">
                  {{ backendData.phoneHint }}
                </div>
                <div
                  v-bind:class="
                    !errors.has('phone') &&
                    !formData.person_attributes.contacts_attributes['1']
                      .value == ''
                      ? 'has-success'
                      : errors.has('phone')
                      ? 'has-error'
                      : ''
                  "
                >
                  <input
                    ref="phone"
                    type="text"
                    v-model="
                      formData.person_attributes.contacts_attributes['1'].value
                    "
                    name="phone"
                    placeholder="Telefonnummer eingeben"
                    v-validate="'required|numeric'"
                  />
                </div>
              </label>
              <div class="validation-error" v-if="errors.has('phone')">
                <p class="error">Bitte gib deine Telefonnummer ein.</p>
              </div>
            </div>
          </div>
          
          <div class="row">
            <div class="col-md-6 col-sm-6">
              <label>
                E-Mail
                <abbr>*</abbr>
                <div class="pull-right">
                  <a
                    class="hint-btn"
                    data-toggle="collapse"
                    href="#hint_e_mail"
                    role="hint_e_mail"
                    aria-expanded="false"
                    aria-controls="hint_e_mail"
                  >
                    <i class="far fa-question-circle"></i>
                  </a>
                </div>
                <div class="clearfix"></div>
                <div id="hint_e_mail" class="hint collapse">
                  {{ backendData.eMailHint }}
                </div>
                <div
                  v-bind:class="
                    !errors.has('email') &&
                    !formData.person_attributes.contacts_attributes['0']
                      .value == ''
                      ? 'has-success'
                      : errors.has('email')
                      ? 'has-error'
                      : ''
                  "
                >
                  <input
                    ref="email"
                    type="email"
                    v-model="
                      formData.person_attributes.contacts_attributes['0'].value
                    "
                    name="email"
                    placeholder="E-Mail eingeben"
                    v-validate="'required|email'"
                  />
                </div>
              </label>
              <div class="validation-error" v-if="errors.has('email')">
                <p class="error">Bitte gib deine E-Mail Adresse ein.</p>
              </div>
            </div>
            <div class="col-md-6 col-sm-6">
              <label>
                E-Mail wiederholen
                <abbr>*</abbr>
                <div
                  v-bind:class="
                    !errors.has('email-repeat') &&
                    !emailEqualityCheck == ''
                      ? 'has-success'
                      : errors.has('email-repeat')
                      ? 'has-error'
                      : ''
                  "
                >
                  <input
                    ref="email-repeat"
                    type="email"
                    v-model="emailEqualityCheck"
                    name="email-repeat"
                    placeholder="E-Mail wiederholen"
                    v-validate="'required|email|confirmed:email'"
                  />
                </div>
              </label>
              <div class="validation-error" v-if="errors.has('email-repeat')">
                <p class="error">Die E-Mail Adressen stimmen nicht überein.</p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-sm-6">
              <label ref="gender" name="gender" for="gender_id">
                Geschlecht
                <abbr>*</abbr>
                <v-select
                  v-bind:class="
                    !errors.has('gender') &&
                    !formData.person_attributes.gender == ''
                      ? 'has-success'
                      : errors.has('gender')
                      ? 'has-error'
                      : ''
                  "
                  data-vv-name="gender"
                  max-height="200px"
                  id="gender_id"
                  v-model="formData.person_attributes.gender"
                  label="gender"
                  :options="['männlich', 'weiblich', 'divers']"
                  placeholder="Geschlecht wählen"
                  class="noclear"
                  v-validate="'required'"
                >
                  <span slot="no-options">Geschlecht nicht angegeben.</span>
                </v-select>
              </label>
              <div class="validation-error" v-if="errors.has('gender')">
                <p class="error">Bitte gib Dein Geschlecht an.</p>
              </div>
            </div>
            <div class="col-md-6 col-sm-6">
              <div class="datepicker-wrapper">
                <label
                  ref="date_of_birth"
                  name="date_of_birth"
                  :title="backendData.dateOfBirthHint"
                  class="date_of_birth"
                >
                  Geburtstag
                  <abbr>*</abbr>
                  <div class="pull-right">
                    <a
                      class="hint-btn"
                      data-toggle="collapse"
                      href="#hint_date_of_birth_id"
                      role="hint_dateofbirth"
                      aria-expanded="false"
                      aria-controls="hint_date_of_birth"
                    >
                      <i class="far fa-question-circle"></i>
                    </a>
                  </div>
                  <div class="clearfix"></div>
                  <div id="hint_date_of_birth_id" class="hint collapse">
                    {{ backendData.dateOfBirthHint }}
                  </div>
                </label>
                <div
                  v-bind:class="
                    !errors.has('date_of_birth') &&
                    !formData.person_attributes.dateofbirth == '' &&
                    !showOutOfDateRangeError
                      ? 'has-success'
                      : errors.has('date_of_birth') || showOutOfDateRangeError
                      ? 'has-error'
                      : ''
                  " 
                >
                  <flat-pickr
                    type="text"
                    id='date_of_birth_id'
                    v-model="formData.person_attributes.dateofbirth"
                    :config="flatPickrConfig"
                    placeholder="Datum wählen"
                    name="date"
                    v-validate="'required'"
                    data-vv-name='date_of_birth'
                  ></flat-pickr>
                </div>
                
              </div>
              <div class="validation-error" v-if="errors.has('date_of_birth')">
                <p class="error">Bitte gib deinen Geburtstag ein.</p>
              </div>
              <div class="validation-error" v-if="showOutOfDateRangeError">
                <p class="error">
                  Dein Geburtsdatum liegt leider ausserhalb der Altersgrenzen
                  für das von dir gewählte Programm.
                </p>
              </div>
            </div>
            <div class="col-md-12">
              <label name="where_did_you_find_out" for="where_did_you_find_out">
                Wie hast du von YFU erfahren?
                <v-select
                  max-height="200px"
                  id="where_did_you_find_out"
                  v-model="formData.where_did_you_find_out"
                  label="where_did_you_find_out"
                  :options="where_did_you_find_out"
                  placeholder="Wie hast du von YFU erfahren?"
                >
                  <span slot="no-options">Wie hast du von YFU erfahren?</span>,
                </v-select>
              </label>
            </div>
            <div class="col-md-12">
              <div class="pull-right">
                <br />
                <strong>
                  Pflichtfelder
                  <abbr>*</abbr>
                </strong>
              </div>
              <div class="clearfix"></div>
            </div>
            <div class="col-lg-12">
              <br />
              <br />
              <p>
                Der Schutz deiner Daten ist uns wichtig:
                <a
                  :href="backendData.privacyPolicyUrl"
                  class="privacy_policy_link"
                  title="Unsere Datenschutzerklärung"
                >
                  <strong>Unsere Datenschutzerklärung</strong>
                </a>
              </p>
              <label class="arrow_submit_wrap">
                <input
                  v-on:click="formData.postal_application = false"
                  type="submit"
                  class="button"
                  value="Weiter"
                />
                <svg
                  class="arrow_submit_icon"
                  xmlns="http://www.w3.org/2000/svg"
                  tilte="Weiter"
                  width="80"
                  height="80"
                  viewBox="0 0 80 80"
                >
                  <use
                    xlink:href="#yfu_arrow_right"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  />
                </svg>
              </label>
            </div>
            <div class="clearfix"></div>
          </div>
        </fieldset>
      </form>
    </div>
    <div v-else>
      <div class="col-12">
        <article class="center-text application-sent-container">
          <!--errors -->
          <span v-if="submitErrors">
            <i class="fa fa-2x fa-frown"></i>
            <p>
              Hoppla, da ist leider etwas schief gelaufen. Bitte probiere es
              noch einmal,
              <strong>{{ formData.person_attributes.firstname }}</strong
              >. Wenn es beim zweiten Mal wieder nicht klappt, schreib uns bitte
              eine kurze E-Mail an
              <a class="contact-link" href="mailto:bewerbung@yfu.de"
                >bewerbung@yfu.de</a
              >.
            </p>
          </span>
          <span v-else>
            <i class="fa fa-2x fa-smile"></i>
            <p>
              <strong>
                Das hat geklappt! Wir freuen uns, dass du deine Bewerbung {{ preRegistrationSuccess ? 'bei YFU starten möchtest,' : 'bei YFU gestartet hast, ' }}
                {{ formData.person_attributes.firstname }}.
              </strong>
            </p>
            <br />
            <!-- applicant already registered -->
            <div v-if="alreadyRegistered && preRegistrationSuccess">
              <p>
                Du bist früh dran: Wir bitten dich noch um etwas Geduld, bis du mit deiner Bewerbung richtig loslegen kannst.
                Voraussichtlich im April 2025 schicken wir dir eine E-Mail mit deinen persönlichen Zugangsdaten zu unserem Bewerbungsportal zu, auf dem du deine Bewerbung dann abschließen kannst.
              </p>
              <p>
                Allerdings ist deine E-Mail-Adresse <strong>{{ formData.person_attributes.contacts_attributes["0"].value }}</strong> bereits bei uns registriert, wahrscheinlich hast du schon einmal eine Bewerbung angefangen.
                Bitte melde dich telefonisch bei uns unter <a class="contact-link" href="tel:0402270020" target="_blank">040 227002-0</a>, damit wir sicherstellen können, dass du dich bei uns bewerben kannst.
              </p>
            </div>
            <div v-else-if="alreadyRegistered">
              <p>
                Allerdings ist deine E-Mail-Adresse
                <strong>{{
                  formData.person_attributes.contacts_attributes["0"].value
                }}</strong>
                bereits bei uns registriert, wahrscheinlich hast du schon einmal
                eine Bewerbung angefangen. Bitte melde dich telefonisch bei uns:
                <a class="contact-link" href="tel:0402270020" target="_blank"
                  >040 227002-0</a
                >.
              </p>
            </div>
            <!-- online application -->
            <div v-else-if="!preRegistrationSuccess && registrationSuccess">
              <p>
                Wir haben dir eine Mail mit deinen persönlichen Zugangsdaten zu
                unserem Bewerbungsportal an deine E-Mail-Adresse
                <strong>{{
                  formData.person_attributes.contacts_attributes["0"].value
                }}</strong>
                geschickt. Du wirst jetzt direkt zu deiner Bewerbung
                weitergeleitet.
              </p>
            </div>
            <div
              v-else-if="preRegistrationSuccess && !formData.postal_application"
            >
              <p>
                Du bist früh dran: Wir bitten dich noch um etwas Geduld, bis du
                mit deiner Bewerbung richtig loslegen kannst. Voraussichtlich April 2025 schicken wir dir eine E-Mail mit deinen persönlichen
                Zugangsdaten zu unserem Bewerbungsportal zu, auf dem du deine
                Bewerbung dann abschließen kannst. Bei Fragen kannst du dich
                natürlich jederzeit gern bei uns melden: per E-Mail an
                <a class="contact-link" href="mailto:info@yfu.de"
                  >info@yfu.de</a
                >
                oder telefonisch unter
                <a class="contact-link" href="tel:0402270020" target="_blank"
                  >040 227002-0</a
                >.
              </p>
            </div>
            <!-- postal application -->
            <div
              v-else-if="!preRegistrationSuccess && formData.postal_application"
            >
              <p>
                Wir schicken dir deine Bewerbungsunterlagen in den nächsten
                Tagen per Post zu. Bei Fragen kannst du dich natürlich jederzeit
                bei uns melden: per E-Mail an
                <a class="contact-link" href="mailto:info@yfu.de"
                  >info@yfu.de</a
                >
                oder telefonisch unter
                <a class="contact-link" href="tel:0402270020" target="_blank"
                  >040 227002-0</a
                >.
              </p>
            </div>
          </span>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import { de } from 'flatpickr/dist/l10n/de'; 

import moment from "moment";
export default {
  components: {
    flatPickr,
    moment,
    "v-select": () => import("vue-select"),
  },
  data() {
    return {
      flatPickrConfig: {
        altFormat: 'd.m.Y',
        altInput: true,
        minDate: new Date(new Date().getFullYear() - 18, 2, 1),
        maxDate: new Date(new Date().getFullYear() - 15, 9, 15),
        locale: 'de', 
      },

      backendData: null,
      element_id: null,
      de: de,
      program_type_seasons: [],
      countries: [],
      showOutOfDateRangeError: false,
      sent: false,
      submitting: false,
      alreadyRegistered: false,
      registrationSuccess: false,
      preRegistrationSuccess: false,
      submitErrors: false,
      applicationUrl:
        "https://intranet.yfu.de/online_application/applications?auth_token=",
      apiErrors: {
        errorBool: false,
        errorMsgs: [],
      },
      loaded: false,
      formData: {
        person_attributes: {
          firstname: "",
          lastname: "",
          gender: "",
          dateofbirth: "",
          contacts_attributes: {
            0: {
              contact_type_id: "",
              value: "",
            },
            1: {
              contact_type_id: "",
              value: "",
            }
          },
          address_attributes: {
            addressline_1: "",
            addressline_2: "",
            zip: "",
            program_type_seasons: "",
            city: "",
            country: {
              case_name: "Deutschland",
              file_flow: "Germany",
              id: null,
              iso_3letter_code: "DEU",
              iso_country_code: "DE",
              name: "Deutschland",
              name_english: "Germany",
              shortname: null,
              yfu_country: "Deutschland",
            },
          },
        },
        interest_source_id: "",
        application_type_id: "",
        program_type_id: "",
        program_season: {},
        postal_application: false,
      },
      emailEqualityCheck: "",
      where_did_you_find_out: [
        "Freunde/Bekannte/Verwandte",
        "Ehemalige Programmteilnehmer",
        "Wir waren Gastfamilie",
        "Infoveranstaltung in der Schule",
        "Lehrer",
        "Internet",
        "Facebook",
        "Instagram",
        "TikTok",
        "YouTube",
        "YFU-Infoveranstaltung",
        "Messe",
        "Flyer/Poster",
        "Fernsehen",
        "Zeitung/Zeitschrift",
      ],
    };
  },
  created() {
    this.$http.get("/api/app_informations").then(
      (resp) => {
        this.program_type_seasons = resp.data.program_type_seasons;
        this.formData.program_season =
          resp.data.program_type_seasons.length > 0
            ? resp.data.program_type_seasons[0]
            : null;
        this.formData.person_attributes.contacts_attributes[
          "0"
        ].contact_type_id = resp.data.email_type;
        this.formData.person_attributes.contacts_attributes[
          "1"
        ].contact_type_id = resp.data.phone_type;
        this.formData.person_attributes.address_attributes.country.id =
          resp.data.germany;
        this.formData.interest_source_id = resp.data.interest_source;
        this.formData.application_type_id = resp.data.application_type;
        this.formData.program_type_id = resp.data.program_type;
      },
      (response) => {
        // show an error on the page
        this.apiErrors.errorBool = true;
      }
    );
    this.$http.get("/api/countries").then(
      (resp) => {
        this.countries = resp.data;
      },
      (response) => {
        this.apiErrors.errorBool = true;
        // show an error on the page
        // remove preset country obj
        this.formData.person_attributes.address_attributes.country = {};
        this.apiErrors.errorBool = true;
      }
    );
  },
  mounted() {
    //when component is mounted to Dom get and push data-rel to data prop
    let dataset = Object.assign({}, this.$el.parentElement.dataset);
    this.backendData = dataset;
    this.element_id = this.backendData.elementId;
    // show content when ready
    this.loaded = true;
    this.submitting = false;
  },
  methods: {
    postData: function (url, obj) {
      this.submitting = true;
      this.$http
        .post("/api/" + url, {
          physical_outbound_interest: this.formData,
        })
        .then(
          (resp) => {
            this.sent = true;
            //needs to be refactored condition should come form server

            if (resp.body.message === "Account existiert schon") {
              this.alreadyRegistered = true;
            } else {
              if (
                resp.body.auth_token !== undefined &&
                this.formData.postal_application === false
                &&
                this.formData.program_season.appliable
              ) {
                this.registrationSuccess = true;
                this.applicationUrl = this.applicationUrl + resp.body.auth_token;
                //redirect to intranet online application
                window.location.replace(this.applicationUrl);
              }
            }

            if(!this.formData.program_season.appliable) {
              this.preRegistrationSuccess = true;
            }

            //track success for ga4
            if (typeof gtag === "function") {
              gtag("event", "EP-Meldeformular abgeschickt", {
                content_type: "Meldeformular versendet (GA4)"
              });
            }
            //track success for facebook

            if (typeof fbq !== "undefined") {
              window.fbq(
                "track",
                "CompleteInterestFormRegistrationWithFacebook"
              );
            }

            //  microsoft ad tracking
            if(window.uetq != undefined) {
              window.uetq = window.uetq || [];      
              window.uetq.push('event', 'signup', {});
            }
          },
          (response) => {
            this.sent = true;
            this.submitErrors = true;
            this.submitting = false;
          }
        );
    },
    sendFormPost: function (url, obj) {
      if (!this.submitting) {
        this.postData(url, obj);
      }
    },
    validateBeforeSubmit(url, obj) {
      //validateAll plus validateDate output this.showOutOfDateRangeError
      this.$validator.validateAll().then((result) => {
        if (result && !this.showOutOfDateRangeError) {
          this.sendFormPost(url, obj);
        } else {
          this.scrollToError();
        }
      });
    },
    scrollToError: function () {
      const firstField = Object.keys(this.errors.collect())[0];
      this.$refs[`${firstField}`].scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    },
    validateDate: function () {
      //validate if dateofbirth is not empty and dateofbirth isBetween program seasons age limits
      if (!this.formData.person_attributes.dateofbirth == "" && this.formData.program_season !== null && this.formData.program_season !== undefined ) {
        let age_limit_min = moment(this.formData.program_season.age_limit_min);
        let age_limit_max = moment(this.formData.program_season.age_limit_max);
        if (
          moment(this.formData.person_attributes.dateofbirth).isBetween(
            age_limit_min,
            age_limit_max
          )
        ) {
          this.showOutOfDateRangeError = false;
        } else {
          this.showOutOfDateRangeError = true;
        }
      } else {
        this.showOutOfDateRangeError = false;
      }
    },
  },
  watch: {
    //watch for changes on programseason and dateofbirth
    "formData.person_attributes.dateofbirth"() {
      this.validateDate();
    },
    "formData.program_season"() {
      if (this.formData.program_season !== null && this.formData.program_season !== undefined) {
        this.flatPickrConfig.minDate = this.formData.program_season.age_limit_min;
        this.flatPickrConfig.maxDate = this.formData.program_season.age_limit_max;
      }
      
      this.validateDate();
    },
  },
};
</script>
